import React from "react";
import ellipse from "./Assets/Ellipse.png";
import frame1 from "./Assets/Frame1.jpg";
import frame2 from "./Assets/Frame2.jpg";
import frame3 from "./Assets/Frame3.jpg";
import frame4 from "./Assets/Frame4.jpg";
import byebye from "./Assets/bye-bye-logo.png";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigator = useNavigate();
  return (
    <div className="bg-neutral-100 px-14 pt-9 h-screen overflow-scroll	">
      <div className="flex justify-between mb-6">
        <div className="flex items-center">
          <img src={byebye} alt="byebye" className="h-10 w-10 bg-neutral-100" />
          <h1 className="text-sidenav-top-purple font-bold text-3xl mt-0 ml-2">
            ByeByeSenders
          </h1>
        </div>
        <button
          className="flex items-center w-[140px] h-[36px] justify-center rounded-md bg-dark-purple py-2 px-3 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => {
            navigator("/privacy-policy");
          }}
        >
          Privacy Policy
        </button>
      </div>

      <div className="flex relative min-h-[212px] ">
        <div className="min-w-[90%] pr-[270px] pt-6 pl-6 bg-white border rounded-lg grid">
          <div>
            <span className="text-sidenav-top-purple font-bold text-3xl flex">
              Stop Email Overload with Our Smart Filter
            </span>
            <span className="text-neutral-400	font-normal text-lg	inline-block align-bottom flex">
              Tired of sorting through endless spam emails? Our email filter can
              help.
            </span>
          </div>
          <div>
            <p className="min-h-[72px] max-w-[700px] text-gray-900 pb-2	font-normal text-base text-justify	">
              Say goodbye to spam and hello to a clutter-free inbox. Our
              advanced email filter uses cutting-edge technology to keep your
              inbox organized and free of unwanted emails. Try it now and start
              enjoying a more productive email experience.
            </p>
          </div>
        </div>
        <div className="h-[268px] w-[268px] border rounded-full absolute right-0 top-0">
          <img src={ellipse} alt="ellipse" />
        </div>
      </div>

      <div className="mt-24">
        <span className="text-gray-900 font-medium text-lg flex items-end	">
          How you can manage your emails and domains with ByeByeSenders
        </span>
      </div>

      <div className="flex justify-start mt-9">
        <div className="w-[508px]">
          <div className="h-[267px] bg-white p-4 rounded-2xl">
            <img
              src={frame1}
              className="h-full w-full rounded-xl"
              alt="frame1"
            />
          </div>
          <div className="flex p-1">
            <span>1.</span>
            <span className="text-gray-900 font-normal text-base text-justify">
              Select the emails you want to filter by clicking on the checkboxes
              next to them. You can select multiple emails at once.
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <div className="w-[508px]">
          <div className="h-[267px] bg-white p-4 rounded-2xl">
            <img
              src={frame2}
              className="h-full w-full rounded-xl"
              alt="frame1"
            />
          </div>
          <div className="flex p-1">
            <span>2.</span>
            <span className="text-gray-900 font-normal text-base text-justify">
              Click on the "Filter" icon at the top of the page.
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-start">
        <div className="w-[508px]">
          <div className="h-[267px] bg-white p-4 rounded-2xl">
            <img
              src={frame3}
              className="h-full w-full rounded-xl"
              alt="frame1"
            />
          </div>
          <div className="flex p-1">
            <span>3.</span>
            <span className="text-gray-900 font-normal text-base text-justify">
              The filter window will appear with filter options on the selected
              emails. You can archive, mark as a read or delete the criteria as
              needed.
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <div className="w-[508px]">
          <div className="h-[267px] bg-white p-4 rounded-2xl">
            <img
              src={frame4}
              className="h-full w-full rounded-xl"
              alt="frame1"
            />
          </div>
          <div className="flex p-1">
            <span>4.</span>
            <span className="text-gray-900 font-normal text-base text-justify">
              You can manage filters on extension by clicking on ‘Learn More’.
              It will redirect to extension, you can manage more thing there.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
