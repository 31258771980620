import React, { useEffect } from "react";
import Navbar from "./Navbar";
import { BsList } from "react-icons/bs";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";

function Home() {
  const [user, setUser] = useState();
  const { state } = useLocation();
  const [count, setCount] = useState([]);
  const [accessToken, setAccessToken] = useState();

  const [refreshToken, setRefreshToken] = useState();
  function getNewAccessToken(refreshToken) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append(
      "client_id",
      // "460275944891-r69qqtccnrjoobv9selsdetr320gqu86.apps.googleusercontent.com"
      "453624703385-lso5ql4kdei1ilb6b6uuf71iik55r55d.apps.googleusercontent.com"
    );
    // urlencoded.append("client_secret", "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");
    urlencoded.append("client_secret", "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");

    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", refreshToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    if (refreshToken && refreshToken.length) {
      fetch("https://oauth2.googleapis.com/token", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("access_token", result.access_token);
          setAccessToken(result.access_token);
          setCount([...count, 1]);
        })
        .catch((error) => console.log("error", error));
    }
  }

  useEffect(() => {
    getNewAccessToken(localStorage.getItem("refresh_token"));
    // setCount([...count, 1]);
  }, [localStorage.getItem("refresh_token"), refreshToken]);

  useEffect(() => {
    setTimeout(() => {
      if (accessToken != undefined) {
        localStorage.setItem("access_token", accessToken);
        axios
          .get(`https://api.byebyesender.com/users?access_token=${accessToken}`)
          .then((res) => {
            setUser(res.data);
            localStorage.setItem("userName", res.data.name);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            localStorage.setItem("refresh_token", res.data.refresh_token);
            localStorage.setItem("access_token", accessToken);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 500);
  }, [count, accessToken, refreshToken]);
  useEffect(() => {
    if (accessToken && accessToken.length) {
      axios
        .get(`https://api.byebyesender.com/users?access_token=${accessToken}`)
        .then((res) => {
          setUser(res.data);
          localStorage.setItem("userName", res.data.name);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("access_token", accessToken);
          getNewAccessToken(res.data.refresh_token);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [refreshToken]);

  const [open, setOpen] = useState(true);

  return (
    <div className="flex min-h-full">
      <div
        className={`bg-dark-purple z-[1]  ${
          open ? "w-280" : "w-0"
        } overflow-hidden duration-300 relative`}
      >
        <Navbar user={user} />
      </div>

      <div className="bg-neutral-100 relative w-full h-full ">
        <div
          className="bg-sidenav-top-purple h-12 w-12 absolute -left-8 top-5 cursor-pointer rotate-45 rounded-tr-lg"
          onClick={() => setOpen(!open)}
        >
          <BsList
            className={`text-white text-3xl absolute -top-30  h-4 w-4 m-0 -rotate-45 left-7 bottom-7  `}
          />
        </div>

        <div className="ml-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;
