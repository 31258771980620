import axios from "axios";

// const url = 'http://localhost:8080/';
const url = "https://api.byebyesender.com/";

//for getting new access_token
function getNewAccessToken(refreshToken) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append(
    "client_id",
    // "460275944891-r69qqtccnrjoobv9selsdetr320gqu86.apps.googleusercontent.com"
    "453624703385-lso5ql4kdei1ilb6b6uuf71iik55r55d.apps.googleusercontent.com"
  );
  // urlencoded.append("client_secret", "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");
  urlencoded.append("client_secret", "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");
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append("refresh_token", refreshToken);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  fetch("https://oauth2.googleapis.com/token", requestOptions)
    .then((response) => response.json())
    .then((result) => localStorage.setItem("access_token", result.access_token))
    .catch((error) => console.log("error", error));
}

export const getAllfiltersService = async () => {
  getNewAccessToken(localStorage.getItem("refresh_token"));
  const access_token = localStorage.getItem("access_token");

  let data;
  try {
    data = await axios.get(`${url}filters?access_token=${access_token}`);
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const removeEmailService = async (deleteObject) => {
  getNewAccessToken(localStorage.getItem("refresh_token"));
  const access_token = localStorage.getItem("access_token");

  let data;
  try {
    data = await axios.patch(
      `${url}filters?access_token=${access_token}`,
      deleteObject
    );
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const deleteFilterService = async (data) => {
  getNewAccessToken(localStorage.getItem("refresh_token"));
  const access_token = localStorage.getItem("access_token");

  let data1;
  try {
    data1 = await axios.delete(`${url}filters`, {
      data,
      params: {
        access_token: access_token,
      },
    });
  } catch (error) {
    console.log(error);
  }
  return data1;
};

export const addFilterService = async (filterType, tempArray) => {
  getNewAccessToken(localStorage.getItem("refresh_token"));
  var ACCESS_TOKEN = localStorage.getItem("access_token");

  const tempObj = {
    from: tempArray,
    access_token: ACCESS_TOKEN,
  };

  let data;
  try {
    data = await axios.post(`${url}filters?code=${filterType}`, tempObj);
  } catch (error) {
    console.log(error);
  }
  return data;
};
