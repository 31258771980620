import React, { useEffect, useState } from "react";
import entensionImg from "./Assets/extension.png";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Login from "./Login";
import { Oval } from  'react-loader-spinner';
import { useNavigate } from "react-router-dom";

export default function SignIn() 
{  
    const navigator = useNavigate();

  const [loader,setLoader] = useState(false)
  useEffect(() => {
    setLoader(true)
    setTimeout(() => {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let extensionEmail =
        localStorage.getItem("extensionEmail") &&
        localStorage.getItem("extensionEmail").replace(/"/g, "");
      if (userInfo && userInfo.email == extensionEmail) {
        setLoader(false)
        localStorage.setItem("nav_value", "Dashboard");
        navigator("/home");
      } else {
        setLoader(false)
        localStorage.clear();
      }
    }, 1500);
  }, []);

  return (
    <div className="static h-full">
      { 
        loader&&loader ?
        <div className='flex justify-center h-full items-center'>
          <Oval
              height={80}
              width={80}
              color="rgb(79, 70, 229)"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="rgb(108, 102, 232)"
              strokeWidth={2}
              strokeWidthSecondary={2}
          />
        </div> :
        <div className="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex p-3 h-96 w-[787px] gap-8 bg-white shadow-box-shadow border rounded-2xl	">

            <div className="bg-[rgb(99,91,232)] rounded-lg flex items-center">
              <img
                className=""
                src={entensionImg}
                alt="chrome extension"
              />
            </div>

            <div className="w-3/6 max-w-md space-y-8 m-2 grid content-around">
              <div className="">
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt="Your Company"
                />
                <h1 className="mt-2 text-center text-3xl font-bold italic tracking-tight text-indigo-600">
                  ByeByeSenders!!!
                </h1>
              </div>
              <div className="" action="#" method="POST">
                <h2 className="text-center text-lg text-neutral-500	">
                  Sign in to your account
                </h2>
                <div className="mx-4 mt-2">
                  <GoogleOAuthProvider clientId="453624703385-lso5ql4kdei1ilb6b6uuf71iik55r55d.apps.googleusercontent.com">
                    <div className="App">
                      <Login />
                    </div>
                  </GoogleOAuthProvider>
                </div>
              </div>
            </div>

          </div> 
        </div> 
      }     
    </div>
    
  );
}
