import React, { useEffect, useState } from 'react'

function Profile() {
  const [userInfo,setUserInfo] = useState('');

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
  },[])

  return (
    <div className='min-h-screen'>
      <div className='p-4 m-8 h-[700px] border bg-white rounded-lg'>
        <h1 className='mb-6 text-sidenav-top-purple font-bold text-3xl flex justify-start'>Profile</h1>
        <div className='flex justify-start'>
          <div>
            <img className="h-[250px] w-[250px] rounded-lg ring-2 ring-white" src={`${userInfo && userInfo.avatar}`} alt="" />
          </div>
          <div className='grid content-center ml-4'>
            
            <div className="">
              <label for="first-name" className="flex justify-start block text-sm font-medium leading-6 text-gray-900">User name</label>
              <div className="mt-2">
                <input type="text" value={userInfo.name} className=" pl-4 w-[500px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6" disabled/>
              </div>
            </div>

            <div className="mt-4">
              <label for="first-name" className="flex justify-start block text-sm font-medium leading-6 text-gray-900">User email</label>
              <div className="mt-2">
                <input type="text" value={userInfo.email} className=" pl-4 w-[500px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6" disabled/>
              </div>
            </div>
        
          </div>
        </div>  
      </div>
    </div>
  )
}

export default Profile