import React, { useEffect, useState } from "react";
import { BsPerson, BsFillPersonFill } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineFilterAlt } from "react-icons/md";
import dashboard from "./Assets/Dashboard.png";
import filter from "./Assets/Filter.png";
import profile from "./Assets/Profile.png";
import logOut from "./Assets/Logout.png";

function Navbar({ user, setUser }) {
  useEffect(() => {}, [localStorage.getItem["access_token"]]);
  const navigator = useNavigate();

  const [selectedNavValue, setSelectedNavValue] = useState("");

  useEffect(() => {
    // const url = window.location.href
    // let temp= url.split("/").pop()
    // setSelectedNavValue(temp)

    if (localStorage.getItem("nav_value")) 
    {
      const temp = localStorage.getItem("nav_value")
      setSelectedNavValue(temp);
    } 
    else 
    {
      localStorage.setItem("nav_value", "Dashboard");
    }

  }, []);

  const logout = () => {
    localStorage.clear();
    navigator("/logout");
    navigator("/");
  };

  return (
    <div>
      <div className="bg-sidenav-top-purple h-154 px-13 pt-5">
        <div className="flex justify-center h-50">
          <img
            className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
            src={`${user && user.avatar}`}
            alt=""
          />
          <strong className="text-white font-medium text-lg m-2">
            {user && user.name}
          </strong>
        </div>
        <hr className="mt-21" />
        <div className="flex justify-center flex-wrap break-all mt-21">
          <span className="text-white text-sm font-medium	h-18 ">
            {user && user.email}
          </span>
        </div>
      </div>

      <div className="text-white mt-5 mx-13 p-2 font-normal">
        <div
          className={`flex justify-start	cursor-pointer p-2 hover:bg-sidenav-top-purple rounded-lg	${
            selectedNavValue === "Dashboard" && "bg-sidenav-top-purple"
          }`}
          onClick={() => {
            navigator(`/home/dashboard`);
            setSelectedNavValue("Dashboard");
            localStorage.setItem("nav_value", "Dashboard");
          }}
        >
          <img src={dashboard} alt="dashboard" />
          <span className="ml-3 text-sm font-medium tracking-wider">
            Dashboard
          </span>
        </div>

        <div
          className={`flex justify-start cursor-pointer p-2 hover:bg-sidenav-top-purple rounded-lg mt-2 ${
            selectedNavValue === "Filters" && "bg-sidenav-top-purple"
          }`}
          onClick={() => {
            navigator(`/home/filters`);
            setSelectedNavValue("Filters");
            localStorage.setItem("nav_value", "Filters");
          }}
        >
          <img src={filter} alt="filter" />
          <span className="ml-3 text-sm font-medium tracking-wider">
            Filters
          </span>
        </div>

        <div
          className={`flex justify-start cursor-pointer p-2 hover:bg-sidenav-top-purple rounded-lg mt-2	${
            selectedNavValue === "Profile" && "bg-sidenav-top-purple"
          } `}
          onClick={() => {
            navigator(`/home/profile`);
            setSelectedNavValue("Profile");
            localStorage.setItem("nav_value", "Profile");
          }}
        >
          <img src={profile} alt="profile" />
          <span className="ml-3 text-sm font-medium tracking-wider">
            Profile
          </span>
        </div>

        <div
          className={`flex justify-start cursor-pointer p-2 hover:bg-sidenav-top-purple rounded-lg mt-2 ${
            selectedNavValue === "Logout" && "bg-sidenav-top-purple"
          }`}
          onClick={() => {
            logout();
            setSelectedNavValue("Logout");
          }}
        >
          <img src={logOut} alt="logout" />
          <span className="ml-3 text-sm font-medium tracking-wider">
            Logout
          </span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
