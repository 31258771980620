import React, { useState } from 'react';
import { addFilterService } from './service/filters';
import { AiOutlinePlus } from 'react-icons/ai';
import { Transition } from '@headlessui/react';
import { Dialog } from '@headlessui/react';
import { Fragment, } from 'react';

function AddFilter(props) 
{
  const [applyFilterDisable,setApplyFilterDisable] = useState(false);
  const [toasterMessageTitle, setToasterMessageTitle]=useState("");
  const [toasterMessageDescription,setToasterMessageDescription] = useState('');  

  //add filters modal
    let [isOpen2, setIsOpen2] = useState(false)

    function closeModal2() 
    {
        setFilterType('');
        setInputFrom('');
        setInputHandler('')
        setApplyFilterDisable(false);
        setAddFilterOpen(0)
        setIsOpen2(false)
    }

    function openModal2() 
    {
        setFilterType('');
        setInputFrom('');
        setInputHandler('')
        setApplyFilterDisable(false);
        setAddFilterOpen(0)
        setIsOpen2(true)
    } 

    //add input handler

    const [inputFrom,setInputFrom] = useState('');
    const [inputHandler,setInputHandler] = useState('');

    const addInputHandler = (e) => {
        if(addFilterOpen>0)
        {
          setInputHandler('');
        }
        setInputFrom(e)
    }

    //add filter radio setting

    const [filterType,setFilterType] = useState('')

    const addFilterRadioHandler = (data) => {
        setFilterType(data)
    }

    const [addFilterOpen,setAddFilterOpen] = useState(0)

    const addFilterHandler = ()=>{
        setAddFilterOpen(prev => prev+1)
        const tempArray = inputFrom.split(",");     
        
        const emailProviders = ["gmail.com","yahoo.com","hotmail.com", "outlook.com","aol.com",
            "protonmail.com","zoho.com","icloud.com","mail.com","gmx.com","yandex.com","mail.ru","tutanota.com",
            "fastmail.com","startmail.com","inbox.lv","rambler.ru","hushmail.com","mailinator.com","runbox.com",
            "zoho.eu","rediffmail.com","yopmail.com","sfr.fr","laposte.net","web.de","gmx.de","mail.ru",
            "seznam.cz","t-online.de","libero.it","tiscali.it","virgilio.it","mailchimp.com","sendgrid.com",
            "amazon.com","microsoft.com",
        ];

        const emailProvidersPresent = tempArray.some(element => {
            return emailProviders.includes(element);
        });       

        if(inputFrom.length===0)
        {
          setInputHandler("*Email can't be empty")
        }
        else if(emailProvidersPresent)
        {
          console.log('hi');
          setInputHandler('*Enter proper email.')
        }

        if(inputFrom.length !== 0 && filterType !== '' && !emailProvidersPresent)
        {
          setApplyFilterDisable(prev => !prev)
          addFilterService(filterType,tempArray)
              .then((res)=>{
                  closeModal2()
                  // setOpen1(prev => prev+1)
                  props.filterAdded()
                  setToasterMessageTitle('Successfully added!');
                  setToasterMessageDescription(filterType + ' filter added successfully.');
              })
              .catch((err)=>{
                  console.log(err)
              })
        }        
    }


  return (
    <div>
      <button onClick={() => openModal2()} className="flex w-[141px] h-[40px] justify-center items-center rounded-md bg-dark-purple py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <AiOutlinePlus/>  <span >Add in filter</span>
      </button>

      <>
        <Transition appear show={isOpen2} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal2}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[651px] h-[550px] relative transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all"
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 flex justify-start"
                    >
                      Add in Filter
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="sm:col-span-3 flex">                                                    
                        <input
                          type="text"
                          autoComplete="given-name"
                          placeholder='Type mail id or domain here'
                          value={inputFrom}
                          className="block w-full h-[88px] rounded-lg p-6 text-gray-900 ring-1 ring-inset ring-gray-200 bg-gray-100 placeholder:text-gray-500 focus:outline-none focus:ring-0 focus:border-dark-purple border sm:text-sm sm:leading-6"
                          onChange={(e) =>
                            {
                              if(e.target.value===" "){
                                  setInputFrom("")
                              }
                              else{
                                  let temp = e.target.value
                                  addInputHandler(temp.replace(/\s/g, ''))
                              }
                            }
                          }
                        />                                            
                      </div>

                      <div>
                        <span className="text-red-500 text-xs">
                          {addFilterOpen>0 && inputHandler!=='' && inputHandler}
                        </span>
                      </div>

                        <div className='h-[58px] w-full mt-6 border rounded-md flex flex-row items-center cursor-pointer'
                            onClick={()=>addFilterRadioHandler('Skip Inbox')}
                        >
                          <input 
                            type='radio' 
                            className='h-4 w-4 mx-4 cursor-pointer border border-red-600	' 
                            id='skip'
                            name='filter'
                            checked={filterType==='Skip Inbox'?true:false}
                            onChange={()=>addFilterRadioHandler('Skip Inbox')}                                                
                          />
                          <span className='font-normal text-base text-gray-900'>
                            Archive (Mark as read and skip inbox)
                          </span>
                        </div>

                        <div className='h-[58px] w-full mt-3 border rounded-md flex flex-row items-center cursor-pointer'
                          onClick={()=>addFilterRadioHandler('Delete it')}
                        >
                          <input 
                            type='radio' 
                            className='h-4 w-4 mx-4 cursor-pointer' 
                            id='delete'
                            name='filter'
                            checked={filterType==='Delete it'?true:false}      
                            onChange={()=>addFilterRadioHandler('Delete it')}                                          
                          />
                          <span className='font-normal text-base text-gray-900'>
                            Delete permanently
                          </span>
                        </div>

                        <div className='h-[58px] w-full mt-3 border rounded-md flex flex-row items-center cursor-pointer'
                          onClick={()=>addFilterRadioHandler('Mark as read')}
                        >
                            <input 
                              type='radio' 
                              className='h-4 w-4 mx-4 cursor-pointer' 
                              id='read'
                              name='filter'
                              checked={filterType==='Mark as read'? true:false}       
                              onChange={()=>addFilterRadioHandler('Mark as read')}                                         
                            />
                            <span className='font-normal text-base text-gray-900'>
                              Mark as read
                            </span>
                        </div>
                        
                    </div>

                    <div>
                      <span className="text-red-500 text-xs">
                        {addFilterOpen>0 && filterType === '' && '*Select filter type.'}
                      </span>
                    </div>

                    <div className="mt-6 flex justify-start fixed bottom-8">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white disabled:cursor-wait"
                        disabled={applyFilterDisable}
                        onClick={()=>addFilterHandler()}
                      >
                        Apply filter
                      </button>
                      <button
                        type="button"
                        className="ml-6 inline-flex justify-center border border-gray-300 rounded-md bg-white px-4 py-2 text-sm font-medium text-base text-gray-700"
                        onClick={()=>{
                            closeModal2();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    </div>
  )
}

export default AddFilter