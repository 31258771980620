import "./App.css";
import SignIn from "./SignIn";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Filters from "./Filters";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Loader from "./Loader"
import Profile from "./Profile";
import Protected from "./Protected";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  return (
    <div className="App h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path={`/home`} element={<Protected><Home /></Protected>}>
            <Route index element={<Protected><Dashboard /></Protected>} />
            <Route path={`Dashboard`} element={<Protected><Dashboard /></Protected>} />
            <Route path={`filters`} element={<Protected><Filters /></Protected>} />
            <Route path={`profile`} element={<Protected><Profile /></Protected>} />
          </Route>
          <Route path="/login" element={<Loader/>}/>
          <Route path="*" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
