import React, { useEffect } from 'react';

import { Oval } from  'react-loader-spinner';

function Loader() 
{
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 2000);
  }, []);
  
  return (
    <div className='flex justify-center h-full items-center'>
      <Oval
          height={80}
          width={80}
          color="rgb(79, 70, 229)"
          wrapperStyle={{}}
          wrapperclassName=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="rgb(108, 102, 232)"
          strokeWidth={2}
          strokeWidthSecondary={2}
      />
    </div>
  )
}

export default Loader;
