import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const redirectUrl = "https://app.byebyesender.com";
// const redirectUrl = "http://localhost:3000";
function Login() {
  const navigator = useNavigate();

  const [toasterMessage, setToasterMessage] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setToasterMessage("");
    }, 3000);
  }, [toasterMessage]);

  const CLIENT_ID =
    // "460275944891-r69qqtccnrjoobv9selsdetr320gqu86.apps.googleusercontent.com";
    "453624703385-lso5ql4kdei1ilb6b6uuf71iik55r55d.apps.googleusercontent.com";
  //previous
  // const SCOPES =
  //   "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.settings.basic https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile";
  const SCOPES =
    "https://www.googleapis.com/auth/gmail.settings.basic https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.profile";
  const authorizeUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&response_type=code&scope=${encodeURIComponent(
    SCOPES
  )}&redirect_uri=${redirectUrl}&access_type=offline`;

  useEffect(() => {
    getCode();
  }, []);
  const handleLocalStorage = () => {
    // window.localStorage.setItem("isThisInLocalStorage", "true");
    window.dispatchEvent(new Event("storage"));
  };

  function getCode() {
    const queryString = window.location.search;
    var code;
    if (queryString)
      code = queryString.split("?")[1].split("&")[0].split("=")[1];
    if (code && code.length && code !== undefined) {
      getRefreshToken(code);
    } else {
      // console.log("code not found");
    }
  }

  function getRefreshToken(code) {
    // navigator("/");

    code = decodeURIComponent(code);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append(
      "client_id",
      // "460275944891-r69qqtccnrjoobv9selsdetr320gqu86.apps.googleusercontent.com"
      "453624703385-lso5ql4kdei1ilb6b6uuf71iik55r55d.apps.googleusercontent.com"
    );
    // urlencoded.append("client_secret", "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");
    urlencoded.append("client_secret", "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");
    urlencoded.append("code", code);
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("redirect_uri", redirectUrl);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://oauth2.googleapis.com/token", requestOptions)
      .then((response) => response.json())
      .then((result) => storeLocalstorageFunction(result))
      .catch((error) => {
        throw new Error(error);
      });
  }

  async function storeLocalstorageFunction(result) {
    if (result && result.refresh_token) {
      navigator("/home");
      localStorage.clear();
      localStorage.setItem("access_token", result.access_token);
      localStorage.setItem("refresh_token", result.refresh_token);
      let googleId = await axios
        .get(
          `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${result.access_token}`
        )
        .then((res) => {
          // write logic here to get User by googleId and navigate to home component
          return res.data.user_id;
        })
        .catch((err) => {
          console.log(err);
        });

      //stored refreshtoken and googleId in DB
      const userBody = {
        refresh_token: result.refresh_token,
        googleId,
      };
      axios
        .post(
          `https://api.byebyesender.com/users?access_token=${result.access_token}`,
          userBody
        )
        .then((res) => {
          navigator("/home");
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (result.access_token) {
      // write logic here to login and store refresh token and access token in localstorage

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${result.access_token}`
        )
        .then((res) => {
          // write logic here to get User by googleId and navigate to home component
          axios
            .get(
              `https://api.byebyesender.com/users/googleId/${res.data.user_id}`
            )
            .then((res) => {
              localStorage.setItem("refresh_token", res.data.refresh_token);

              getNewAccessToken(res.data.refresh_token);
              localStorage.removeItem("nav_value");

              navigator("/home");
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // console.log("in else");
      // setToasterMessage("Email is already exists!!");
    }
  }

  const [user, setUser] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const register = () => {
    window.location.href = authorizeUrl;
  };
  function getNewAccessToken(refreshToken) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append(
      "client_id",
      // "460275944891-r69qqtccnrjoobv9selsdetr320gqu86.apps.googleusercontent.com"
      "453624703385-lso5ql4kdei1ilb6b6uuf71iik55r55d.apps.googleusercontent.com"
    );
    // urlencoded.append("client_secret", "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");
    urlencoded.append("client_secret", "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");
    urlencoded.append("grant_type", "refresh_token");
    urlencoded.append("refresh_token", refreshToken);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    if (refreshToken && refreshToken.length) {
      console.log("i am here");
      fetch("https://oauth2.googleapis.com/token", requestOptions)
        .then((response) => response.json())
        .then((result) =>
          localStorage.setItem("access_token", result.access_token)
        )
        .catch((error) => console.log("error", error));
    }
  }
  return (
    <div className="static">
      <a
        type="submit"
        onClick={() => register()}
        className="cursor-pointer	 group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        Sign in
      </a>

      {toasterMessage && (
        <div className="absolute bottom-0 left-5">
          {toasterMessage === "User not registered!!" && (
            <div
              id="alert-2"
              className="flex p-4 mb-4 text-red-800 border border-red-500 rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Info</span>
              <div className="ml-3 text-sm font-medium mx-6">
                {toasterMessage}
              </div>
              <button
                type="button"
                className="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                data-dismiss-target="#alert-2"
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          )}

          {toasterMessage === "Email is already exists!!" && (
            <div
              id="alert-3"
              className="flex p-4 mb-4 text-green-800 border border-green-500 rounded-lg bg-green-100 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Info</span>
              <div className="ml-3 text-sm font-medium mx-6">
                {toasterMessage}
              </div>
              <button
                type="button"
                className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                data-dismiss-target="#alert-3"
                aria-label="Close"
              >
                <span className="sr-only">Close</span>
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Login;
