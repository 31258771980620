import React from 'react';
import { Navigate } from 'react-router-dom';

function Protected({children}) {
  if (localStorage.getItem('refresh_token')===null) 
  {
    return <Navigate to="/" replace />
  }
  return children
}

export default Protected;