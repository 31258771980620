import React, { useEffect, useState } from 'react';
import { Fragment, } from 'react';
import { Transition } from '@headlessui/react';
import { Dialog } from '@headlessui/react';
import gifImg from './Assets/ezgif.com-gif-to-mp4.gif';
import { Oval } from  'react-loader-spinner';
import { RxCross2 } from 'react-icons/rx';
import { BsArrowDownUp } from 'react-icons/bs';
import {RiDeleteBin6Line} from 'react-icons/ri'
import { getAllfiltersService,removeEmailService, deleteFilterService,addFilterService } from './service/filters';
import { successToaster } from './Toaster/success';
import { AiOutlinePlus } from 'react-icons/ai';
import AddFilter from './AddFilter';
import { MdOutlineModeEdit } from 'react-icons/md';

function Filters({email}) 
{
    const [toasterMessageTitle, setToasterMessageTitle]=useState("")
    const [toasterMessageDescription,setToasterMessageDescription] = useState('')
    const [loading,setLoading] = useState(true);
    const [selectedFilterData, setSelectedFilterData] = useState('');
    const [filtersData,setFiltersData] = useState([]);    
    const [open1,setOpen1] = useState(0)
    const [display,setDisplay] = useState('email');
    const [deleteFilterDisable,setDeleteFilterDisable] = useState([]);
    const [applyFilterDisable,setApplyFilterDisable] = useState(false);
    const [emailsDeleteButtonDisable,setEmailsDeleteButtonDisable] = useState(false);
    const [domainsDeleteButtonDisable,setDomainsDeleteButtonDisable] = useState(false);
    const [selectedFilterEmailsDisable,setSelectedFilterEmailsDisable] = useState([]);
    const [selectedFilterDomainsDisable,setSelectedFilterDomainsDisable] = useState([]);
    const [searchEmail,setSearchEmail] = useState('')
    const [searchDomain,setSearchDomain] = useState('')
    let [isOpen, setIsOpen] = useState(false)

    useEffect(()=> {
        setTimeout(()=>{
        setToasterMessageTitle('')
        setToasterMessageDescription('')
        }, 3000)
    },[toasterMessageTitle])    

    useEffect(() => {
        getAllfiltersService()
            .then((res)=>{
                setFiltersData(res.data);
                setDeleteFilterDisable(new Array(res.data.length).fill(false))
                setLoading(false)
            })
            .catch((error)=>{
                console.log(error);
            })
    }, [open1]);  

    useEffect(()=>{
        setSelectedFilterEmailsDisable(new Array(selectedFilterData&&selectedFilterData.emails.length).fill(false));
        setSelectedFilterDomainsDisable(new Array(selectedFilterData&&selectedFilterData.domains.length).fill(false));
    },[selectedFilterData]);
    
    function closeModal() 
    {
        setDisplay('');                
        setSearchEmail('');
        setSearchDomain('');
        setIsOpen(false)
    }

    function openModal() 
    {                   
        setIsOpen(true)
    }
    
    const removeEmail = (selectedFilterData,emailname,display,emailIndex) => {

        if(display==='email')
        {
            const temp = selectedFilterEmailsDisable.map((item,index) => {
                if(index === emailIndex)
                {
                    return !item
                }
                else
                {
                    return item
                }
            })
            setSelectedFilterEmailsDisable(temp);
        }
        else
        {
            const temp = selectedFilterDomainsDisable.map((item,index) => {
                if(index === emailIndex)
                {
                    return !item
                }
                else
                {
                    return item
                }
            })
            setSelectedFilterDomainsDisable(temp);
        }

        const deleteObject = {
            code: selectedFilterData.code,            
            from: [emailname],
        }

        removeEmailService(deleteObject)
            .then((res) => {
                if(res.data.length===0)
                {
                    
                    setOpen1(prev=>prev+1)
                    closeModal()
                }
                else
                {
                    const tempArray = filtersData.map((data) => {
                        if(data.code === res.data[0].code)
                        {
                            return res.data[0]
                        }
                        else
                        {
                            return data
                        }
                    })
                    setFiltersData(tempArray);
                    const tempEmailArray = res.data[0].emails.map(item => {
                        return {
                            ...item,
                            checked:false
                        }
                    })
                    const tempDomainArray = res.data[0].domains.map(item => {
                        return {
                            ...item,
                            checked:false
                        }
                    })
                    
                    setSelectedFilterData({
                        ...res.data[0],
                        emails:tempEmailArray,
                        domains:tempDomainArray
                    });

                    

                    if(display==='email')
                    {
                        if(res.data[0].emails.length===0)
                        {
                            setDisplay('domain');
                        }
                        else
                        {
                            setDisplay('email');
                        }
                    }
                    else if(display==='domain')
                    {
                        if(res.data[0].domains.length===0)
                        {
                            setDisplay('email');
                        }
                        else
                        {
                            setDisplay('domain');
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })           
                    
    }

    const deleteAllMultipleEmailsFromSelectedFilter = (data) => {

        setEmailsDeleteButtonDisable(prev => !prev);

        let temp1 = data.emails.map(item => {
            if(item.checked===true)
            {
                return {
                    email:item.email,
                    id:item.id
                }
            }
            else
            {
                return null
            }
        })
        
        let temp2 = temp1.filter(item => item!==null)
        let array = [...temp2]
        const deleteObject = {
            code: data.code,            
            from: array,
        }

        removeEmailService(deleteObject)
            .then((res) => {
                setEmailsDeleteButtonDisable(prev => !prev)
                if(res.data.length === 0)
                {
                    setOpen1(prev=>prev+1)
                    closeModal()
                }
                else
                {
                    const tempArray = filtersData.map((data) => {
                        if(data.code === res.data[0].code)
                        {
                            return res.data[0]
                        }
                        else
                        {
                            return data
                        }
                    })
                    setFiltersData(tempArray);
                    const tempEmailArray = res.data[0].emails.map(item => {
                        return {
                            ...item,
                            checked:false
                        }
                    })
                    const tempDomainArray = res.data[0].domains.map(item => {
                        return {
                            ...item,
                            checked:false
                        }
                    })
                    setSelectedFilterData({
                        ...res.data[0],
                        emails:tempEmailArray,
                        domains:tempDomainArray
                    });
                    if(res.data[0].emails.length>0)
                    {
                        setDisplay('email');
                    }
                    else
                    {
                        setDisplay('domain');
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })  
    }

    const deleteAllMultipleDomainsFromSelectedFilter = (data) => {

        setDomainsDeleteButtonDisable(prev => !prev);

        let temp1 = data.domains.map(item => {
            if(item.checked===true)
            {
                return {
                    email:item.email,
                    id:item.id
                }
            }
            else
            {
                return null
            }
        })
        
        let temp2 = temp1.filter(item => item!==null)
        let array = [...temp2]
        const deleteObject = {
            code: data.code,            
            from: array,
        }

        removeEmailService(deleteObject)
            .then((res) => {
                setDomainsDeleteButtonDisable(prev => !prev);
                if(res.data.length === 0)
                {
                    setOpen1(prev=>prev+1)
                    closeModal()
                }
                else
                {
                    const tempArray = filtersData.map((data) => {
                        if(data.code === res.data[0].code)
                        {
                            return res.data[0]
                        }
                        else
                        {
                            return data
                        }
                    })
                    setFiltersData(tempArray);
                    const tempEmailArray = res.data[0].emails.map(item => {
                        return {
                            ...item,
                            checked:false
                        }
                    })
                    const tempDomainArray = res.data[0].domains.map(item => {
                        return {
                            ...item,
                            checked:false
                        }
                    })
                    setSelectedFilterData({
                        ...res.data[0],
                        emails:tempEmailArray,
                        domains:tempDomainArray
                    });
                    if(res.data[0].emails.length>0)
                    {
                        setDisplay('email');
                    }
                    else
                    {
                        setDisplay('domain');
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })  
    }
     
    //delete handler

    const deleteFilterHanlder = (selectedFilterData,filterIndex) => {

        const temp = deleteFilterDisable.map((item,index) => {
            if(index === filterIndex)
            {
                return !item
            }
            else
            {
                return item
            }
        })
        setDeleteFilterDisable(temp)

        const {id } = selectedFilterData

        const data = {
           id
        };

        deleteFilterService(data)
            .then((res) => {
                setOpen1(prev => prev+1)
                setToasterMessageTitle('Successfully deleted!');
                setToasterMessageDescription(selectedFilterData.code + ' filter delete successfully.');
            })
            .catch((error) => {
                console.log(error);
            })   
    }

    //sorting

    const [sort,setSort] = useState('increase')
    const sortingEmailFunc = () => {  
        if(display==='email')
        {
            if(sort==='increase')
            {
                let tempArray = selectedFilterData.emails.sort((a,b) => (a.email < b.email) ? 1 : -1)
                setSelectedFilterData({
                    ...selectedFilterData,
                    emails:tempArray
                })
                setSort('decrease')
            }
            else
            {
                let tempArray = selectedFilterData.emails.sort((a,b) => (a.email > b.email) ? 1 : -1)
                setSelectedFilterData({
                    ...selectedFilterData,
                    emails:tempArray
                })
                setSort('increase')
            }
        }
        else if(display==='domain')      
        {
            if(sort==='increase')
            {
                let tempArray = selectedFilterData.domains.sort((a,b) => (a.email < b.email) ? 1 : -1)
                setSelectedFilterData({
                    ...selectedFilterData,
                    domains:tempArray
                })
                setSort('decrease')
            }
            else
            {
                let tempArray = selectedFilterData.domains.sort((a,b) => (a.email > b.email) ? 1 : -1)
                setSelectedFilterData({
                    ...selectedFilterData,
                    domains:tempArray
                })
                setSort('increase')
            }
        }        
    }

    const filterAdded = () => {
        setOpen1(prev => prev+1);
        setToasterMessageTitle('Successfully added!');
        // setToasterMessageDescription(filterType + ' filter added successfully.');
    }

    return (
        <div className='bg-neutral-100 h-screen mt-8 static'>

            <div className='flex justify-between px-4 sm:px-8'>
                <span className="text-2xl font-semibold leading-tight text-primary text-gray-900">
                    Filters
                </span>

                <div className='flex justify-center'>
                    <AddFilter filterAdded={filterAdded} />
                </div>
            </div>

            <div className='flex justify-center mt-8'>
                <Oval
                    height={80}
                    width={80}
                    color="rgb(79, 70, 229)"
                    wrapperStyle={{}}
                    wrapperclassName=""
                    visible={loading}
                    ariaLabel='oval-loading'
                    secondaryColor="rgb(108, 102, 232)"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>            

            {
                filtersData.length===0 && loading===false && <div>
                    <div>
                        <h2 className="text-base font-light">No any filters created!</h2>
                        <div className='flex justify-center w-full mt-10'>
                            <img src={gifImg} alt='img' />
                            {/* No filter created */}
                        </div>
                        
                    </div>
                </div>
            }
            {
                filtersData.length>0&&loading===false && <div>

                    <div className="container mx-auto px-4 sm:px-8">
                        <div className="py-4">
                            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-center	 text-base font-semibold text-gray-600 tracking-wider">
                                                    Filter name
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-center	 text-base font-semibold text-gray-600 tracking-wider">
                                                    Email count
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-center	 text-base font-semibold text-gray-600 tracking-wider">
                                                Domain count
                                                </th>
                                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-center text-base font-semibold text-gray-600 tracking-wider">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                filtersData && filtersData.map((filterItem,filterIndex) => {
                                                    return <tr className='cursor-pointer border-b bg-white border-gray-200 hover:bg-gray-100' key={filterIndex} >
                                                        <td className="px-5 py-4 text-center text-sm"  
                                                        onClick={()=>{
                                                            const tempEmailArray = filterItem.emails.map(item => {
                                                                return {
                                                                    ...item,
                                                                    checked:false
                                                                }
                                                            })
                                                            const tempDomainArray = filterItem.domains.map(item => {
                                                                return {
                                                                    ...item,
                                                                    checked:false
                                                                }
                                                            })
                                                            setSelectedFilterData({
                                                                ...filterItem,
                                                                emails:tempEmailArray,
                                                                domains:tempDomainArray
                                                            });
                                                            if(filterItem.emails.length>0)
                                                            {
                                                                setDisplay('email');
                                                            }
                                                            else
                                                            {
                                                                setDisplay('domain');
                                                            } 
                                                            openModal()
                                                        }}>
                                                            <p className="m-0 text-gray-900 whitespace-no-wrap">
                                                                {filterItem.code}
                                                            </p>
                                                        </td>
                                                        <td className="px-5 py-4 text-center text-sm" 
                                                        onClick={()=>{
                                                            const tempEmailArray = filterItem.emails.map(item => {
                                                                return {
                                                                    ...item,
                                                                    checked:false
                                                                }
                                                            })
                                                            const tempDomainArray = filterItem.domains.map(item => {
                                                                return {
                                                                    ...item,
                                                                    checked:false
                                                                }
                                                            })
                                                            setSelectedFilterData({
                                                                ...filterItem,
                                                                emails:tempEmailArray,
                                                                domains:tempDomainArray
                                                            });
                                                            if(filterItem.emails.length>0)
                                                            {
                                                                setDisplay('email');
                                                            }
                                                            else
                                                            {
                                                                setDisplay('domain');
                                                            } 
                                                            openModal()
                                                        }}>
                                                            <p className="m-0 text-gray-900 whitespace-no-wrap">
                                                                {filterItem && filterItem.emails.length}
                                                            </p>
                                                        </td>
                                                        <td className="px-5 py-4 text-center text-sm" 
                                                        onClick={()=>{
                                                            const tempEmailArray = filterItem.emails.map(item => {
                                                                return {
                                                                    ...item,
                                                                    checked:false
                                                                }
                                                            })
                                                            const tempDomainArray = filterItem.domains.map(item => {
                                                                return {
                                                                    ...item,
                                                                    checked:false
                                                                }
                                                            })
                                                            setSelectedFilterData({
                                                                ...filterItem,
                                                                emails:tempEmailArray,
                                                                domains:tempDomainArray
                                                            });
                                                            if(filterItem.emails.length>0)
                                                            {
                                                                setDisplay('email');
                                                            }
                                                            else
                                                            {
                                                                setDisplay('domain');
                                                            } 
                                                            openModal()
                                                        }}>
                                                            <p className="m-0 text-gray-900 whitespace-no-wrap text-blue-500">
                                                                {filterItem && filterItem.domains.length} 
                                                            </p>
                                                        </td>
                                                        <td className="px-5 py-4 text-right text-sm ">
                                                            <div className='flex items-center justify-center gap-3'>
                                                                <MdOutlineModeEdit size={18} className=' hover:text-black text-gray-500'
                                                                    disabled={deleteFilterDisable[filterIndex]}
                                                                    onClick={()=>{
                                                                        const tempEmailArray = filterItem.emails.map(item => {
                                                                            return {
                                                                                ...item,
                                                                                checked:false
                                                                            }
                                                                        })
                                                                        const tempDomainArray = filterItem.domains.map(item => {
                                                                            return {
                                                                                ...item,
                                                                                checked:false
                                                                            }
                                                                        })
                                                                        setSelectedFilterData({
                                                                            ...filterItem,
                                                                            emails:tempEmailArray,
                                                                            domains:tempDomainArray
                                                                        });
                                                                        if(filterItem.emails.length>0)
                                                                        {
                                                                            setDisplay('email');
                                                                        }
                                                                        else
                                                                        {
                                                                            setDisplay('domain');
                                                                        } 
                                                                        openModal()
                                                                    }}
                                                                />
                                                                <RiDeleteBin6Line size={18}  className={`hover:text-black text-gray-500 disabled:cursor-wait`}
                                                                    disabled={deleteFilterDisable[filterIndex]}
                                                                    onClick={() => {
                                                                        deleteFilterHanlder(filterItem,filterIndex)
                                                                    }}
                                                                />
                                                            </div>
                                                       
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* onClick on filters */}

                    <>
                        
                        <Transition appear show={isOpen} as={Fragment}>
                            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-[756px]  h-[630px] transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                                        <div className='flex justify-between'>
                                            <Dialog.Title 
                                                as="h3"
                                                className="text-xl font-medium leading-6 text-gray-900 flex justify-start mb-8"
                                            >
                                                {selectedFilterData.code}
                                            </Dialog.Title>
                                            <RxCross2 className='h-4 w-4 cursor-pointer' onClick={() => closeModal()} />
                                        </div>
                                    
                                        <div className='flex justify-between'>
                                            <div className='flex grid grid-flow-col justify-stretch p-[1px] h-[43px] w-[230px] border rounded-xl border-dark-purple	'>
                                                <div 
                                                    className={`rounded-xl flex justify-center grid content-center ${display ==='email'? 'bg-dark-purple text-white':'bg-white text-dark-purple' } ${selectedFilterData&&selectedFilterData.emails.length===0?'cursor-not-allowed':'cursor-pointer'}    `} 
                                                    disabled={selectedFilterData&&selectedFilterData.emails.length===0 ? true : false}
                                                    onClick={()=>{
                                                        if(selectedFilterData.emails.length!==0)
                                                        {
                                                            setSearchEmail('')
                                                            setDisplay('email')
                                                            setSort('increase')
                                                        }
                                                    }}
                                                >
                                                    <span>Email</span>
                                                </div>
                                                <div 
                                                    className={`rounded-xl flex justify-center grid content-center ${display ==='domain'? 'bg-dark-purple text-white':'bg-white text-dark-purple' }  ${selectedFilterData&&selectedFilterData.domains.length===0?'cursor-not-allowed':'cursor-pointer'} `} 
                                                    disabled={selectedFilterData&&selectedFilterData.domains.length===0 ? true : false}
                                                    onClick={()=>{
                                                        if(selectedFilterData.domains.length!==0)
                                                        {
                                                            setSearchDomain('');
                                                            setDisplay('domain')
                                                            setSort('increase')
                                                        }
                                                    }}
                                                >
                                                    <span>Domain</span>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <input type="text" className="border w-80 h-[43px] rounded-md px-4 focus:border-dark-purple focus:outline-none" placeholder="Search..." 
                                                    value={display==='email' ? searchEmail : searchDomain}
                                                    onChange={(event) => {
                                                        if(display==='email')
                                                        {
                                                            setSearchEmail(event.target.value);
                                                        }
                                                        else
                                                        {
                                                            setSearchDomain(event.target.value);
                                                        }                                                        
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-6 h-[350px] overflow-y-scroll inline-block min-w-full  shadow rounded-lg overflow-hidden">
                                            <table className="min-w-full leading-normal">
                                                <thead>
                                                    <tr>
                                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-base font-semibold text-gray-600 tracking-wider">
                                                            <input type="checkbox" className="form-checkbox focus:outline-none focus:shadow-outline cursor-pointer"  
                                                                checked={
                                                                    display==='email' ?
                                                                        selectedFilterData && selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).length === selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).filter(item=>item.checked===true).length  && selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).filter(item=>item.checked===true).length>0 ? true : false :
                                                                        selectedFilterData && selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).length === selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).filter(item=>item.checked===true).length && selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).filter(item=>item.checked===true).length>0 ? true : false
                                                                }
                                                                onChange={(event) => {
                                                                    if(display==='email')
                                                                    {
                                                                        const temp = selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).map((item,index) => {
                                                                                return {
                                                                                    ...item,
                                                                                    checked:event.target.checked
                                                                                }
                                                                        })
                                                                        setSelectedFilterData({
                                                                            ...selectedFilterData,
                                                                            emails:temp
                                                                        })
                                                                    }
                                                                    else
                                                                    {
                                                                        const temp = selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).map((item,index) => {
                                                                            return {
                                                                                ...item,
                                                                                checked:event.target.checked
                                                                            }
                                                                        })
                                                                        setSelectedFilterData({
                                                                            ...selectedFilterData,
                                                                            domains:temp
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </th>
                                                        <th className="flex items-center px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-left text-base font-semibold text-gray-600 tracking-wider">
                                                            {
                                                                display==='email' ? 
                                                                <div className='flex items-center'>
                                                                    Emails
                                                                    <BsArrowDownUp className='ml-2 cursor-pointer'
                                                                    onClick={()=>sortingEmailFunc()}
                                                                    />
                                                                </div> : 
                                                                <div className='flex items-center'>
                                                                    Domains
                                                                    <BsArrowDownUp className='ml-2 cursor-pointer'
                                                                    onClick={()=>sortingEmailFunc()}
                                                                    />
                                                                </div> 
                                                            }
                                                        </th>
                                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-200 text-right text-base font-semibold text-gray-600 tracking-wider">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (selectedFilterData && display==='email') && selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).map((emailItem,emailIndex) => {
                                                            return <tr key={emailIndex} className='border-b border-gray-200 bg-white  hover:bg-gray-100 cursor-pointer'>
                                                                <td className="px-5 py-4 text-left text-sm">
                                                                    <input type="checkbox" className="form-checkbox focus:outline-none focus:shadow-outline cursor-pointer" 
                                                                        id={emailItem.email}
                                                                        name={emailItem.email}
                                                                        checked={emailItem.checked}
                                                                        onChange={() => {
                                                                            const temp = selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).map((item,index) => {
                                                                                if(index === emailIndex)
                                                                                {
                                                                                    return {
                                                                                        ...item,
                                                                                        checked:!item.checked
                                                                                    }
                                                                                }
                                                                                else
                                                                                {
                                                                                    return item
                                                                                }
                                                                            })
                                                                            setSelectedFilterData({
                                                                                ...selectedFilterData,
                                                                                emails:temp
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="px-5 py-4 text-left text-sm"
                                                                    onClick={() => {
                                                                        const temp = selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).map((item,index) => {
                                                                            if(index === emailIndex)
                                                                            {
                                                                                return {
                                                                                    ...item,
                                                                                    checked:!item.checked
                                                                                }
                                                                            }
                                                                            else
                                                                            {
                                                                                return item
                                                                            }
                                                                        })
                                                                        setSelectedFilterData({
                                                                            ...selectedFilterData,
                                                                            emails:temp
                                                                        })
                                                                    }}
                                                                >
                                                                    <p className="text-gray-900 whitespace-no-wrap m-0">
                                                                        {emailItem.email}
                                                                    </p>
                                                                </td>
                                                                <td className="px-5 py-4 text-right text-sm">
                                                                    <p className={`whitespace-no-wrap text-red-500 m-0 ${selectedFilterEmailsDisable[emailIndex] ? 'cursor-progress' : 'cursor-pointer'}`}
                                                                        disabled={selectedFilterEmailsDisable[emailIndex]}
                                                                        onClick={() => {
                                                                            removeEmail(selectedFilterData,emailItem,display,emailIndex)
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </p>                               
                                                                </td>
                                                            </tr>
                                                        })
                                                    }  
                                                    {
                                                        (selectedFilterData && display==='domain' ) && selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).map((domainItem,domainIndex) => {
                                                            return <tr key={domainIndex} className=' border-b border-gray-200 bg-white hover:bg-gray-100 cursor-pointer'>
                                                                <td className="px-5 py-4text-left text-sm">
                                                                    <input type="checkbox" className="form-checkbox focus:outline-none focus:shadow-outline cursor-pointer" 
                                                                        id={domainItem.email}
                                                                        name={domainItem.email}
                                                                        checked={domainItem.checked}
                                                                        onChange={() => {
                                                                            const temp = selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).map((item,index) => {
                                                                                if(index === domainIndex)
                                                                                {
                                                                                    return {
                                                                                        ...item,
                                                                                        checked:!item.checked
                                                                                    }
                                                                                }
                                                                                else
                                                                                {
                                                                                    return item
                                                                                }
                                                                            })
                                                                            setSelectedFilterData({
                                                                                ...selectedFilterData,
                                                                                domains:temp
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="px-5 py-4 text-left text-sm" 
                                                                    onClick={() => {
                                                                        const temp = selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).map((item,index) => {
                                                                            if(index === domainIndex)
                                                                            {
                                                                                return {
                                                                                    ...item,
                                                                                    checked:!item.checked
                                                                                }
                                                                            }
                                                                            else
                                                                            {
                                                                                return item
                                                                            }
                                                                        })
                                                                        setSelectedFilterData({
                                                                            ...selectedFilterData,
                                                                            domains:temp
                                                                        })
                                                                    }}
                                                                >
                                                                    <p className="text-gray-900 whitespace-no-wrap m-0">
                                                                        {domainItem.email}
                                                                    </p>
                                                                </td>
                                                                <td className="px-5 py-4 text-right text-sm">
                                                                    <p className={`whitespace-no-wrap text-red-500 m-0 ${selectedFilterDomainsDisable[domainIndex] ? 'cursor-progress':'cursor-pointer' }`} 
                                                                        disabled={selectedFilterDomainsDisable[domainIndex]}
                                                                        onClick={() => {
                                                                            removeEmail(selectedFilterData,domainItem,display,domainIndex)
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </p>                               
                                                                </td>
                                                            </tr>
                                                        })
                                                    } 
                                                </tbody>
                                            </table>
                                        </div>

                                        {
                                            display==='email' && <div className="mt-2 flex">
                                                {
                                                    selectedFilterData && selectedFilterData.emails.filter(item => item.checked===true).length>0 &&
                                                    <div>
                                                        <button type="button" className={`${emailsDeleteButtonDisable ? 'cursor-progress' : 'cursor-pointer'} rounded-md border border-transparent mr-4 bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2`}
                                                            disabled={emailsDeleteButtonDisable}
                                                            onClick={() => {
                                                                deleteAllMultipleEmailsFromSelectedFilter(selectedFilterData)
                                                            }}
                                                        >
                                                            Delete ({selectedFilterData.emails.filter(item => item.checked===true).length})
                                                            {/* Delete ({selectedEmailsArray.filter(item => item === true).length}) */}
                                                        </button>
                                                        <button type="button" className="rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                            onClick={() => {
                                                                const temp = selectedFilterData.emails.filter(item => item.email.includes(searchEmail)).map((item,index) => {
                                                                    return {
                                                                        ...item,
                                                                        checked:false
                                                                    }      
                                                                })
                                                                setSelectedFilterData({
                                                                    ...selectedFilterData,
                                                                    emails:temp
                                                                })
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>                                                    
                                                }                                                
                                            </div>
                                        }

                                        {
                                            display==='domain' && <div className="mt-2 flex">
                                                {
                                                    selectedFilterData && selectedFilterData.domains.filter(item => item.checked===true).length>0 &&
                                                    <div>
                                                        <button type="button" className={`${domainsDeleteButtonDisable ? 'cursor-progress' : 'cursor-pointer'} rounded-md border border-transparent mr-4 bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2`}
                                                            disabled={domainsDeleteButtonDisable}
                                                            onClick={()=>{
                                                                deleteAllMultipleDomainsFromSelectedFilter(selectedFilterData)
                                                            }}
                                                        >
                                                            Delete ({selectedFilterData.domains.filter(item => item.checked===true).length})
                                                        </button>
                                                        <button type="button" className="rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                            onClick={() => {
                                                                const temp = selectedFilterData.domains.filter(item => item.email.includes(searchDomain)).map((item,index) => {
                                                                    return {
                                                                        ...item,
                                                                        checked:false
                                                                    }
                                                                })
                                                                setSelectedFilterData({
                                                                    ...selectedFilterData,
                                                                    domains:temp
                                                                })
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>                                                    
                                                }                                                
                                            </div>
                                        }

                                    </Dialog.Panel>                            
                                </Transition.Child>
                                </div>
                            </div>
                            </Dialog>
                        </Transition>
                    </>                   
                
                </div>
            }

            {/* toaster */}
            {
                toasterMessageTitle && <div className='absolute top-3 right-5'>
                    { toasterMessageTitle==='Successfully deleted!' && successToaster(toasterMessageTitle,toasterMessageDescription) }
                    { toasterMessageTitle==='Successfully added!' && successToaster(toasterMessageTitle,toasterMessageDescription) }
                </div>
            }            

        </div>    
    )
}

export default Filters;